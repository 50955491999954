import classNames from "classnames"
import React, { PropsWithChildren } from "react"

import Masonry from "../../Masonry"

/*
  This Masonry implementation uses a columnsPerBreakpoint config in order to determine how many flex div columns
  are rendered. The width of each column is equal (width 100% if there is only 1 column). Each column's height is then tracked
  and re-calculated after each child element passed into the Masonry component is auto-placed (into the column with thelowest height).
*/

type Props = {
  className?: string
}

const MasonryGrid = ({ children, className }: PropsWithChildren<Props>) => {
  return (
    <Masonry
      className={classNames(
        "flex w-auto items-start gap-20 md:gap-24 lg:gap-32 xl:gap-40",
        className
      )}
      columnClassName="flex flex-col gap-20 md:gap-24 lg:gap-32 xl:gap-40"
      multiColumnClassName="last:mt-40 md:last:mt-48 lg:last:mt-64 xl:last:mt-80"
    >
      {children}
    </Masonry>
  )
}

export default MasonryGrid
