import React, { PropsWithChildren } from "react"

import { Color } from "../../../constants/V2/color"
import GridSectionTitles, {
  GridSectionTitleProps,
} from "../../elements/V2/GridSectionTitles"
import LayoutSection, { CTAProps } from "../LayoutComponents/LayoutSection"
import MasonryGrid from "../LayoutComponents/MasonryGrid"

import { getAccentColor } from "@utils/V2/color"

type Props = PropsWithChildren<
  GridSectionTitleProps &
    CTAProps & {
      id?: string
      headingSize?: "small" | "large"
      alignment?: "center" | "left"
      overrideSuperscriptColor?: Color
      overrideHeadingTextColor?: Color
      overrideHeadingBodyColor?: Color
      primaryBackgroundColor: Color
      secondaryBackgroundColor?: Color
    }
>

const MasonryGridSection = ({
  id,
  headingSize = "large",
  alignment = "center",
  superscriptText,
  overrideSuperscriptColor,
  headingText,
  overrideHeadingTextColor,
  headingBodyText,
  overrideHeadingBodyColor,
  primaryBackgroundColor,
  secondaryBackgroundColor,
  ctaType,
  ctaColor,
  ctaText,
  ctaLink,
  trackingEvent,
  trackingEventKey,
  trackingEventValue,
  children,
  ...props
}: Props) => {
  return (
    <LayoutSection
      id={id}
      ctaType={ctaType}
      ctaText={ctaText}
      ctaLink={ctaLink}
      ctaColor={ctaColor}
      trackingEvent={trackingEvent}
      trackingEventKey={trackingEventKey}
      trackingEventValue={trackingEventValue}
      primaryBackgroundColor={primaryBackgroundColor}
      secondaryBackgroundColor={secondaryBackgroundColor}
      {...props}
    >
      {(superscriptText || headingText || headingBodyText) && (
        <GridSectionTitles
          size={headingSize}
          alignment={alignment}
          superscriptText={superscriptText}
          superscriptColor={
            overrideSuperscriptColor || getAccentColor(primaryBackgroundColor)
          }
          headingText={headingText}
          headingTextColor={
            overrideHeadingTextColor || getAccentColor(primaryBackgroundColor)
          }
          headingBodyText={headingBodyText}
          headingBodyColor={
            overrideHeadingBodyColor || getAccentColor(primaryBackgroundColor)
          }
        />
      )}

      <MasonryGrid>{children}</MasonryGrid>
    </LayoutSection>
  )
}

export default MasonryGridSection
